import React from 'react';
import styled from 'styled-components';

const SubscribeForm = styled.form`
    position:relative;
`;

const Input = styled.input`
    width:585px;
    height:45px;
    padding:5px 10px;
    font-size:16px;
    line-height:32px;
    border-radius:0px;
    border:2px solid white;

    ::placeholder,
    ::-webkit-input-placeholder {
      font-family: ${props => props.theme.fonts.secondFont};
    }
    :-ms-input-placeholder {
       font-family: ${props => props.theme.fonts.secondFont};
    }

    @media (max-width:786px) {
        width:calc( 100vw - 30px );
        font-size:12px;
        -webkit-appearance: none; box-shadow: none !important;
        outline: none;
    }

    @media only screen 
    and (min-device-width : 768px) 
    and (max-device-width : 1024px) 
    and (orientation : portrait) { 
        width:calc( 100vw - 260px );
    }

    @media only screen and (max-device-width: 780px) and (min-device-width: 451px) and (orientation: landscape) {
        width:calc( 100vw - 260px );
    }
`;

const Button = styled.button`
    border:0;
    position:absolute;
    top:0px;
    right:0;
    background-color: #006575;
    color:#FFFFFF;
    font-size:22px;
    line-height:32px;
    letter-spacing:0.96px;
    height: 45px;
    padding: 0px 30px;
    margin: 0;
    cursor: pointer;
    font-family: ${props => props.theme.fonts.firstFont};
`;

const Newsletter = () =>
    <SubscribeForm
        id="subForm"
        className="js-cm-form"
        action="https://www.createsend.com/t/subscribeerror?description="
        method="post"
        data-id="92D4C54F0FEC16E5ADC2B1904DE9ED1AFB65C9D8C0A57054797BD655EDA928D8D12A9388C09A04111BDB7CC93261730861FD43D9E0FCBA5FE31EB4C3CA615705"
    >
        <Input
            id="fieldEmail"
            type="email"
            className="js-cm-email-input qa-input-email"
            autocomplete="Email"
            aria-label="Email"
            maxlength="200"
            name="cm-jrtkidj-jrtkidj"
            required
            placeholder="Email"
        />
        <Button type="submit">Subscribe</Button>
    </SubscribeForm>
export default Newsletter;