import React from "react"
import styled from 'styled-components'
import Card from "../images/card.jpg";
// import PreOrderCard from "../images/order_here_click_card.jpg";

const Container = styled.div`
    display:flex;
    flex-direction:row;
    justify-content: space-evenly;
    width:100%;
`;

const Img = styled.img`
    height: 55vh;

    /* With 3 images */
    @media only screen 
    and (min-device-width : 768px) 
    and (max-device-width : 1024px) 
    and (orientation : portrait) { 
        &:nth-child(2n), 
        &:nth-child(3n) {
            display:none;
        }
    }
    
    /* With 2 images and link in the middle */
    /* @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation:landscape) {
        &.left-img {
            display:block;
        }
    }
    
    @media(max-width:1024px) and (orientation:portrait){
        &.left-img {
            display:none;
        }
    }
    
    @media(max-width:1240px) {
        &.right-img {
            display:none;
        }
    } */
    
    @media only screen and (min-device-width: 1112px) and (orientation: landscape) { 
        margin-left:30px;
        margin-right:30px;
        height: 45vh;
    }
    @media only screen 
    and (min-device-width : 768px) 
    and (max-device-width : 1024px) 
    and (orientation : landscape) { 
        margin-left:30px;
        margin-right:30px;
        height: 45vh;
    }
    @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
    and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation:portrait) {
        height: 35vh;
    }
    @media only screen 
    and (min-device-width : 375px) 
    and (max-device-width : 667px) { 
        height: 45vh;
    }
    
    /* With 3 images */
    @media(max-width:480px) {
        &:nth-child(2n), 
        &:nth-child(3n) {
            display:none;
        }
    }
    
    /* With 2 images and link in the middle */
    /* @media only screen 
    and (min-device-width : 280px) 
    and (max-device-width : 320px) { 
        height: 35vh;
    } */
`;

/* With 2 images and link in the middle */
// const TFLink = styled.a`
//    text-decoration:none;
//    color:white;
//    cursor:pointer;
// `;

export default () =>
    <Container>
        {/* With 3 images */}
        <Img src={Card} alt="Coming soon card - Words in Papiamento - Aruba" />
        <Img src={Card} alt="Coming soon card - Words in Papiamento - Aruba" />
        <Img src={Card} alt="Coming soon card - Words in Papiamento - Aruba" />

        {/* With 2 images and link in the middle */}
        {/* <Img className="left-img" src={Card} alt="Coming soon card - Words in Papiamento - Aruba" />
        <Img className="left-img" src={Card} alt="Coming soon card - Words in Papiamento - Aruba" />
        <TFLink
            className="typeform-share button"
            href="https://form.typeform.com/to/ElXoxCBW"
            data-mode="popup"
            target="_blank"
            rel="noopener"
        >
            <Img src={PreOrderCard} alt="Pre-order card - Words in Papiamento - Aruba" />
        </TFLink>
        <Img className="right-img" src={Card} alt="Coming soon card - Words in Papiamento - Aruba" /> */}
    </Container>