import { createGlobalStyle } from 'styled-components';
import SkarpaLiteTTF from './fonts/SkarpaLt.ttf';
import SpecialEliteRegular from './fonts/SpecialElite-Regular.ttf';

export default createGlobalStyle`
    *,
    *:after,
    *:before {
        box-sizing:border-box;
    }
    html { scroll-behavior: smooth; }
    body {
        margin:0;
        padding:0;
        font-family: ${props => props.theme.fonts.firstFont};
        color: ${props => props.theme.colors.defaultPrimary};
        background-color: ${props => props.theme.colors.defaultSecondary};
        font-size: ${props => props.theme.fontSizes.small};
        font-weight: ${props => props.theme.fontWeight.regular};
        font-display:fallback;
    }
    main {
        height: 100% !important;
    }
    @font-face {
        font-family: 'Skarpa Lite';
        src:url(${SkarpaLiteTTF}) format('truetype'); 
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'Special Elite Regular';
        src:url(${SpecialEliteRegular}) format('truetype'); 
        font-weight: normal;
        font-style: normal;
    }
    h1, h2, h3, h4, h5, h6 {
        margin-top:0;
        margin-bottom:${props => props.theme.spacing.inset};
    }
    p, 
    ul > li {
        margin-top:0;
        margin-bottom:0;
    }
`;