import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    min-width: 45px;
    width: 45px;
    max-width: 45px;
    height: 45px;
    min-height: 45px;
    max-height: 45px;
    color: grey;
    user-select: none;
`;

const SVG = styled.svg`
    width: 100%;
    height: 100%;
    color:${props => props.theme.colors[props.fontColor]};
`;

const FacebookIcon = ({ className }) => (
    <Container className={className} >
        <SVG xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52">
            <g id="Grupo_30" data-name="Grupo 30" transform="translate(-1612.5 -51.225)">
                <circle id="Elipse_2" data-name="Elipse 2" cx="26" cy="26" r="26" transform="translate(1612.5 51.225)" fill="#006575" />
                <path id="Trazado_1" data-name="Trazado 1" d="M1673.668,50.1H1669.2V44.816h4.473V40.634a6.886,6.886,0,0,1,1.858-5.14,6.733,6.733,0,0,1,4.938-1.83,28.8,28.8,0,0,1,4.064.232v4.7h-2.788a2.7,2.7,0,0,0-2.149.7,2.978,2.978,0,0,0-.464,1.858v3.659h4.937l-.7,5.285h-4.24V63.4h-5.459Z" transform="translate(-38.439 28.622)" fill="#fff" />
            </g>
        </SVG>
    </Container>
);

export default FacebookIcon;