import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    min-width: 45px;
    width: 45px;
    max-width: 45px;
    height: 45px;
    min-height: 45px;
    max-height: 45px;
    color: grey;
    user-select: none;
`;

const SVG = styled.svg`
    width: 100%;
    height: 100%;
    color:${props => props.theme.colors[props.fontColor]};
`;

const InstagramIcon = ({ className }) => (
    <Container className={className} >
        <SVG xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52">
            <g id="Grupo_29" data-name="Grupo 29" transform="translate(-1679.5 -51.225)">
                <circle id="Elipse_1" data-name="Elipse 1" cx="26" cy="26" r="26" transform="translate(1679.5 51.225)" fill="#006575" />
                <path id="Trazado_2" data-name="Trazado 2" d="M1750.338,44.417q.065,1.825.065,6.063t-.1,6.1a12.294,12.294,0,0,1-.555,3.227,7.637,7.637,0,0,1-4.628,4.629,12.316,12.316,0,0,1-3.227.554q-1.858.1-6.1.1t-6.1-.1a10.628,10.628,0,0,1-3.227-.62,6.947,6.947,0,0,1-2.836-1.727,7.586,7.586,0,0,1-1.792-2.836,12.239,12.239,0,0,1-.555-3.227q-.1-1.858-.1-6.1t.1-6.1a12.238,12.238,0,0,1,.555-3.227,7.631,7.631,0,0,1,4.628-4.629,12.309,12.309,0,0,1,3.227-.554q1.857-.1,6.1-.1t6.1.1a12.316,12.316,0,0,1,3.227.554,7.637,7.637,0,0,1,4.628,4.629A13.224,13.224,0,0,1,1750.338,44.417Zm-3.13,14.669a13.735,13.735,0,0,0,.522-3.521q.065-1.435.065-4.042V49.437q0-2.673-.065-4.042a13.09,13.09,0,0,0-.522-3.521,4.7,4.7,0,0,0-2.8-2.8,13.061,13.061,0,0,0-3.522-.522q-1.433-.065-4.041-.065h-2.087q-2.607,0-4.042.065a13.694,13.694,0,0,0-3.521.522,4.706,4.706,0,0,0-2.8,2.8,13.094,13.094,0,0,0-.523,3.521q-.065,1.435-.064,4.042v2.086q0,2.608.064,4.042a13.74,13.74,0,0,0,.523,3.521,4.94,4.94,0,0,0,2.8,2.8,13.694,13.694,0,0,0,3.521.522q1.435.065,4.042.065h2.087q2.673,0,4.041-.065a13.062,13.062,0,0,0,3.522-.522A4.94,4.94,0,0,0,1747.208,59.086Zm-11.408-16.1a7.287,7.287,0,0,1,3.748,1.011,7.517,7.517,0,0,1,2.739,2.738,7.459,7.459,0,0,1,0,7.5,7.517,7.517,0,0,1-2.739,2.738,7.454,7.454,0,0,1-7.5,0,7.515,7.515,0,0,1-2.738-2.738,7.461,7.461,0,0,1,0-7.5,7.515,7.515,0,0,1,2.738-2.738A7.292,7.292,0,0,1,1735.8,42.982Zm0,12.388a4.9,4.9,0,1,0-3.456-1.435A4.708,4.708,0,0,0,1735.8,55.37Zm9.583-12.713a1.752,1.752,0,1,0-1.76,1.76,1.591,1.591,0,0,0,1.207-.522A2.037,2.037,0,0,0,1745.383,42.656Z" transform="translate(-30.057 26.41)" fill="#fff" />
            </g>
        </SVG>
    </Container>
);

export default InstagramIcon;