import React from 'react';
import styled from 'styled-components';
import Logo from './logo';
import Cards from './cards';
import Newsletter from './newsletter';
import SocialList from "./social-list";

const Container = styled.div`
    width: 100%;
    display:flex;
    flex-direction:column;
    align-items: center;
    height:100vh;

    @media only screen 
    and (min-device-width : 768px) 
    and (max-device-width : 1024px) 
    and (orientation : portrait) { 
        height:100vh;
    }
    @media only screen and (min-device-width: 1112px) and (orientation: landscape) { 
        height:100vh;
    }
    @media only screen 
    and (min-device-width : 768px) 
    and (max-device-width : 1024px) 
    and (orientation : landscape) { 
        height:100vh;
    }
    @media only screen and (min-device-width: 480px) and (max-device-width: 896px) and (orientation: landscape) { 
        height:100%;
    }
    @media only screen and (min-device-width: 362px) and (max-device-width: 568px)
    and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation:portrait) {
        height:100vh;
    }
    @media only screen and (min-device-width: 321px) and (max-device-width: 361px){
        height:100%;
    }
`;

const LowerContainer = styled.footer`
    padding: 80px 0px 30px 0px;
    display:flex;
    flex-direction:row;

    @media(max-width:480px) {
        padding-top:30px;
        flex-direction:column;
        align-items:center;
    }
`;

export default () =>
    <Container>
        <Logo />
        <Cards />
        <LowerContainer>
            <SocialList />
            <Newsletter />
        </LowerContainer>
    </Container>