import React from "react"
import styled from 'styled-components'
import { Link } from 'gatsby'
import LogoBrand from '../images/logo.svg'

const LogoLink = styled(Link)`
    display:block;
`;
const H1 = styled.h1``;

const Image = styled.img`
    max-height: 130px;

    @media(max-width:480px) {
        max-height:110px;
    }
`;

export default () =>
    <H1>
        <LogoLink to="/" title="Words in Papiamento">
            <Image src={LogoBrand} alt="Words in Papiamento" />
        </LogoLink>
    </H1>