import React from "react";
import { graphql } from "gatsby";
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import SEO from "../components/seo";
import Theme from "../styles/theme";
import GlobalStyles from "../styles/theme/global-styles";
import Content from '../components/content';
import OGImage from '../images/logo-image.jpg';

const ContainerWithBackgroundImage = ({ className, file }) =>
    <BackgroundImage className={className} Tag="main" fluid={file?.childImageSharp?.fluid}>
        <Content />
    </BackgroundImage>

const Main = styled(props => <ContainerWithBackgroundImage {...props} />)`
    width:100%;
`;

export const query = graphql`
  {
    file(relativePath: {eq: "background.jpg"}) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`

const IndexPage = ({ data }) => {

    const scripts = [
        <script key="GA-script">{`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);}  gtag('js', new Date());  gtag('config', 'UA-96836368-17');`}</script>,
        <script>{`(function() { var qs,js,q,s,d=document, gi=d.getElementById, ce=d.createElement, gt=d.getElementsByTagName, id="typef_orm_share", b="https://embed.typeform.com/"; if(!gi.call(d,id)){ js=ce.call(d,"script"); js.id=id; js.src=b+"embed.js"; q=gt.call(d,"script")[0]; q.parentNode.insertBefore(js,q) } })()`}</script>
    ];
    return (
        <Theme>
            <SEO image={OGImage} scripts={scripts} />
            <GlobalStyles />
            <Main {...data} />
        </Theme>
    )
}
export default IndexPage