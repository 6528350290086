import React from 'react';
import styled from 'styled-components';
import FacebookIcon from '../icons/facebook';
import InstagramIcon from '../icons/instagram';

const SocialList = styled.ul`
    display:flex;
    list-style-type:none;
    padding:0;
    margin:0;

    @media(max-width:480px) {
        margin-bottom:30px;
    }
`;

const ListItem = styled.li`
    margin-right:20px;
`;

const HyperLink = styled.a``;

export default () =>
    <SocialList>
        <ListItem>
            <HyperLink href="https://www.facebook.com/wordsinpapiamento/" title="Like us on Facebook" target="_blank" rel="noopener">
                <FacebookIcon />
            </HyperLink>
        </ListItem>
        <ListItem>
            <HyperLink href="https://www.instagram.com/wordsinpapiamento/" title="Follow us on Instagram" target="_blank" rel="noopener">
                <InstagramIcon />
            </HyperLink>
        </ListItem>
    </SocialList>